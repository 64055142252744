import React, { useState } from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

import { mainMenu } from '../../utils/menus';

const logo = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: 'rgba(0, 0, 0, 0)',
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: 'rgba(0, 0, 0, 1)',
  },
};

interface Props {
  location: string;
}

export function MainMenu({ location }: Props) {
  const [isActive, setisActive] = useState(false);

  const menuItems = mainMenu.template.map(item => {
    if (item.hasOwnProperty('submenu')) {
      return (
        <div key={item.id} className="navbar-item has-dropdown is-hoverable">
          <Link
            className="navbar-link is-arrowless has-text-weight-bold"
            to={`${item.link === '' ? location : item.link}`}
          >
            {item.label.toUpperCase()}
          </Link>

          <div className="navbar-dropdown">
            {item.submenu?.map(dropdownItem => {
              if (dropdownItem.label === 'separator') {
                return <hr key={dropdownItem.id} className="navbar-divider" />;
              }
              return (
                <Link
                  key={dropdownItem.id}
                  className="navbar-item"
                  to={`${dropdownItem.link}`}
                >
                  {dropdownItem.label}
                </Link>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <Link
          key={item.id}
          className="navbar-item has-text-weight-bold"
          to={`${item.link}`}
        >
          {item.label.toUpperCase()}
        </Link>
      );
    }
  });

  return (
    <nav
      className={`navbar is-transparent is-fixed-top${
        isActive ? ' is-active' : ''
      }`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 177.56429 90.564629"
            version="1.1"
            style={{ height: '40px' }}
          >
            <motion.path
              style={{ stroke: '#000000', strokeWidth: 2 }}
              d="m 42.441726,55.920595 q 0.77977,0 0.77977,1.67093 0,2.00513 -3.007682,8.80026 -2.896286,6.79513 -8.911648,12.5877 -5.903967,5.79257 -14.592825,5.79257 -7.129319,0 -11.91933,-4.45582 Q 0,75.860405 0,66.614575 q 0,-8.68886 4.010242,-17.15493 4.010242,-8.57746 10.471187,-13.92445 6.460945,-5.45838 13.367473,-5.45838 4.121637,0 6.906528,1.89372 2.896285,1.78233 2.896285,5.2356 0,3.89884 -4.455824,7.5749 -4.344429,3.67605 -11.362352,6.46094 -7.017923,2.78489 -14.481429,4.01025 -1.336747,5.12419 -1.336747,10.0256 0,6.46095 3.007681,10.02561 3.119077,3.56466 8.800253,3.56466 8.577462,0 13.924451,-6.12676 5.346989,-6.12676 8.911649,-13.81306 0.222791,-0.44558 0.779769,-1.67093 0.556978,-1.33675 1.00256,-1.33675 z m -9.802813,-17.48911 q 0,-1.78233 -1.893725,-2.78489 -1.78233,-1.00256 -4.233033,-1.00256 -4.233033,0 -8.13188,2.78489 -3.898846,2.78489 -6.572341,6.68374 -2.562098,3.89884 -3.341868,6.90652 5.124198,-0.55697 10.805374,-2.89628 5.681176,-2.33931 9.468627,-5.1242 3.898846,-2.78489 3.898846,-4.56722 z"
              variants={logo}
              initial="hidden"
              animate="visible"
              transition={{
                default: { yoyo: 'Infinity', duration: 2, ease: 'easeInOut' },
                fill: { yoyo: 'Infinity', duration: 2, ease: [1, 0, 0.8, 1] },
              }}
            />
            <motion.path
              style={{ stroke: '#000000', strokeWidth: 2 }}
              d="m 106.71686,27.848902 q 0,0.556978 -1.55954,1.559543 -1.44814,1.00256 -1.78233,1.44814 -5.792575,6.57234 -10.805375,18.82586 -5.0128,12.25351 -7.90909,26.51215 -0.33418,1.67093 -1.11395,6.68374 l -0.55698,3.89884 q -0.33419,1.78233 -1.55954,2.78489 -1.113956,1.00256 -4.010242,1.00256 -0.556978,0 -3.787451,-6.68373 -3.230472,-6.79513 -6.795132,-14.59283 l -4.790011,-10.47118 q -4.344429,-9.46863 -7.240714,-14.81562 -2.896286,-5.34699 -4.233033,-5.34699 -0.445583,0 -0.77977,0.55698 -0.222791,0.55698 -0.556978,0.55698 -0.556978,0 -1.00256,-0.44559 -0.334187,-0.55697 -0.334187,-1.11395 0,-1.44814 1.559539,-3.78745 1.670934,-2.33931 3.230472,-2.33931 1.113956,0 1.670934,0.44558 0.556978,0.44559 1.002561,1.00256 0.445582,0.55698 0.668373,0.89117 2.673495,3.34187 9.245836,17.6005 2.339307,5.01281 11.585143,26.17797 l 2.562099,5.90397 q 0,-2.89629 1.113956,-9.35723 2.450705,-13.14468 7.352105,-26.06657 4.90141,-12.92189 11.47375,-21.610752 1.336755,-1.78233 2.116525,-2.450703 0.89116,-0.77977 2.22791,-0.77977 0.55697,0 1.78233,1.78233 1.22535,1.670934 1.22535,2.227912 z"
              variants={logo}
              initial="hidden"
              animate="visible"
              transition={{
                default: { yoyo: 'Infinity', duration: 2, ease: 'easeInOut' },
                fill: { yoyo: 'Infinity', duration: 2, ease: [1, 0, 0.8, 1] },
              }}
            />
            <motion.path
              style={{ stroke: '#000000', strokeWidth: 2 }}
              d="m 118.97036,14.592825 q 0,1.670934 -3.67606,1.670934 -1.89372,0 -3.89885,-1.559538 -1.89373,-1.559539 -1.89373,-2.562099 0,-1.225352 1.11396,-2.339308 1.22535,-1.113956 2.4507,-1.113956 0,0 0.77978,0.334187 0.89117,0.222791 2.00512,1.448143 0.89117,0.891164 2.00512,2.339307 1.11396,1.448143 1.11396,1.78233 z m 2.78489,70.06784 q 0,1.11395 -1.00256,1.67093 -0.89117,0.66837 -2.11652,0.55698 -1.55954,-0.1114 -2.33931,-5.0128 -0.66838,-4.90141 -1.44814,-18.60307 -0.44559,-9.02304 -0.77978,-17.60051 -0.33418,-8.68885 -0.44558,-13.36747 0,-1.22535 0.33419,-1.78233 0.44558,-0.66837 1.55954,-0.66837 2.56209,0 3.23047,2.11651 0.66837,2.11652 0.66837,8.13188 l -0.11139,13.14469 q 0,11.25095 0.66838,18.04608 0,0.33419 1.00255,7.12932 0.77978,4.67862 0.77978,6.23816 z"
              variants={logo}
              initial="hidden"
              animate="visible"
              transition={{
                default: { yoyo: 'Infinity', duration: 2, ease: 'easeInOut' },
                fill: { yoyo: 'Infinity', duration: 2, ease: [1, 0, 0.8, 1] },
              }}
            />
            <motion.path
              style={{ stroke: '#000000', strokeWidth: 2 }}
              d="m 175.78195,8.13188 q 0,2.227912 -0.22279,8.800253 -0.22279,6.460945 -0.22279,13.033282 0,12.6991 0.55699,25.95518 0.55698,13.14468 0.89116,17.26632 0,0.66837 0.11139,1.44814 0.1114,0.77977 0.22279,1.44815 0.44558,3.11907 0.44558,3.78745 0,1.11395 -1.67092,2.11651 -1.55954,1.00256 -2.45071,1.00256 -1.22535,0 -1.67094,-0.89116 -0.33418,-1.00256 -0.33418,-2.6735 0,-6.12676 0.33418,-12.81049 l 0.1114,-6.23816 q 0,-3.11907 -0.1114,-8.13187 -0.11139,-5.1242 -0.33418,-8.57747 -5.68118,18.26888 -12.92189,30.29961 -7.12932,11.91933 -15.48399,11.91933 -3.78745,0 -6.46095,-3.56466 -2.56209,-3.56466 -2.56209,-10.2484 0,-8.80025 5.90396,-19.16004 6.01536,-10.35979 13.59026,-17.8233 7.6863,-7.463504 11.36236,-7.463504 3.56465,0 3.56465,2.339304 0,1.55954 -1.11395,3.67606 -1.00255,2.00512 -2.33931,3.67605 -1.22535,1.67094 -1.44814,1.67094 -0.22279,0 -0.55698,-0.1114 -0.22279,-0.22279 -0.22279,-0.44558 0,-0.55698 0.55698,-1.89373 0.55698,-1.33674 0.55698,-1.78233 0,-0.55697 -0.1114,-0.77976 -0.1114,-0.2228 -0.66837,-0.2228 -2.33931,0 -8.46607,6.46095 -6.01536,6.34955 -10.80537,15.1498 -4.79002,8.80026 -4.79002,16.37516 0,2.67349 1.11396,5.0128 1.11396,2.33931 3.67606,2.33931 8.13188,0 15.0384,-13.70166 7.01793,-13.81306 12.14212,-31.30217 l -0.22279,-9.35723 q -0.33418,-12.364912 -0.33418,-13.144681 0,-6.683737 0.55697,-9.580023 0.2228,-1.113956 0.44559,-1.559538 Q 171.77171,0 172.66288,0 q 1.11395,0 2.22791,0.77977 1.11396,0.668373 1.11396,1.893725 z"
              variants={logo}
              initial="hidden"
              animate="visible"
              transition={{
                default: { yoyo: 'Infinity', duration: 2, ease: 'easeInOut' },
                fill: { yoyo: 'Infinity', duration: 2, ease: [1, 0, 0.8, 1] },
              }}
            />
          </motion.svg>
        </Link>

        <Link
          to="/"
          role="button"
          className={`navbar-burger burger${isActive ? ' is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => {
            setisActive(!isActive);
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </Link>
      </div>

      <div
        id="mainMenu"
        className={`navbar-menu${isActive ? ' is-active' : ''}`}
      >
        {menuItems}

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Link className="button is-light" to="/">
                Prijava
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
