export const mainMenu = {
  name: 'main',
  template: [
    {
      id: 'main10s00s00',
      label: 'Evidencija',
      link: '/',
      icon: '',
      submenu: [
        {
          id: 'main10s05s00',
          label: 'Udruge',
          link: '/',
          icon: '',
        },
        {
          id: 'main10s10s00',
          label: 'Grupe',
          link: '/',
          icon: '',
        },
        {
          id: 'main10s15s00',
          label: 'Članovi',
          link: '/',
          icon: '',
        },
        {
          id: 'main10s18s00',
          label: 'Oprema i materijal',
          link: '/',
          icon: '',
        },
        {
          id: 'main10s20s00',
          label: 'separator',
          link: '/',
          icon: '',
        },
        {
          id: 'main10s25s00',
          label: 'Aktivnosti',
          link: '/',
          icon: '',
        },
        {
          id: 'main10s30s00',
          label: 'Postignuća',
          link: '/',
          icon: '',
        },
      ],
    },
    {
      id: 'main20s00s00',
      label: 'Dokumenti',
      link: '/',
      icon: '',
      submenu: [
        {
          id: 'main20s05s00',
          label: 'Članarine',
          link: '/',
          icon: '',
        },
        {
          id: 'main20s10s00',
          label: 'Uplatnice',
          link: '/',
          icon: '',
        },
        {
          id: 'main20s15s00',
          label: 'Potvrde',
          link: '/',
          icon: '',
        },
        {
          id: 'main20s20s00',
          label: 'Reversi',
          link: '/',
          icon: '',
        },
        {
          id: 'main20s25s00',
          label: 'Ostalo',
          link: '/',
          icon: '',
        },
      ],
    },
    {
      id: 'main30s00s00',
      label: 'Računovodstvo',
      link: '/',
      icon: '',
      submenu: [
        {
          id: 'main30s05s00',
          label: 'Stanja',
          link: '/',
          icon: '',
        },
        {
          id: 'main30s10s00',
          label: 'Unosi',
          link: '/',
          icon: '',
        },
        {
          id: 'main30s15s00',
          label: 'Analize',
          link: '/',
          icon: '',
        },
      ],
    },
    {
      id: 'main40s00s00',
      label: 'Izvještaji',
      link: '',
      icon: '',
      submenu: [
        {
          id: 'main40s05s00',
          label: 'Grupe',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s10s00',
          label: 'Članovi',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s15s00',
          label: 'Oprema i materijal',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s20s00',
          label: 'Aktivnosti',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s25s00',
          label: 'Postignuća',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s30s00',
          label: 'separator',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s35s00',
          label: 'Registri',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s40s00',
          label: 'Mailovi',
          link: '/',
          icon: '',
        },
      ],
    },
    {
      id: 'main50s00s00',
      label: 'Alati',
      link: '',
      icon: '',
      submenu: [
        {
          id: 'main50s05s00',
          label: 'Uvoz podataka',
          link: '/',
          icon: '',
        },
        {
          id: 'main50s10s00',
          label: 'Izvoz podataka',
          link: '/',
          icon: '',
        },
        {
          id: 'main50s15s00',
          label: 'separator',
          link: '',
          icon: '',
        },
        {
          id: 'main50s20s00',
          label: 'Mailing lista',
          link: '/',
          icon: '',
        },
        {
          id: 'main50s25s00',
          label: 'Postavke',
          link: '/',
          icon: '',
        },
      ],
    },
  ],
};
