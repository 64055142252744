import React from 'react';
// import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { MainMenu } from '../components/navbar';

const IndexPage = (props: any) => (
  <Layout>
    <SEO title="Home" />
    <div className="container is-fluid home">
      <MainMenu location={props.location.pathname} />
    </div>
  </Layout>
);

export default IndexPage;
